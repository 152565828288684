import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.widget': {
    '.abyss-card-root': {
      position: 'relative',
      padding: 'var(--abyss-space-lg) var(--abyss-space-md)',
      borderColor: 'var(--abyss-colors-gray4)',
      boxShadow: 'none',
    },
    h6: {
      color: 'var(--abyss-colors-black) !important',
      marginBottom: 'var(--abyss-space-xs)',
    },
    '.abyss-divider-root': {
      backgroundColor: 'var(--abyss-colors-gray3)',
      marginTop: 'var(--abyss-space-md)',
      marginBottom: 'var(--abyss-space-lg)',
    },
  },
});
