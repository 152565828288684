import PropTypes from 'prop-types';
import React from 'react';
import { CurrentEnvironment } from '@src/components/CurrentEnvironment';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Header } from '@src/layouts/default/Header';
import { StickyContainer } from 'react-sticky';
import { Visibility } from '@src/components/Visibility';
import { Main } from './Main';

/**
 * Layout
 *
 * Glues together the various pieces of the layout.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const Layout = (props) => {
  const { children } = props;

  return (
    <ErrorHandler location="src/layouts/default/Layout.jsx">
      <Visibility>
        <StickyContainer>
          <CurrentEnvironment />
          <Header />
          <Main>{children}</Main>
        </StickyContainer>
      </Visibility>
    </ErrorHandler>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
