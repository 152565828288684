import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '#headerLeft': {
    position: 'relative',
    marginBottom: 'var(--abyss-space-sm)',
    '.abyss-toggle-switch-root': {
      height: '100%',
    },
  },
  '.bottomRow': {
    paddingTop: 'var(--abyss-space-sm)',
    paddingBottom: 'var(--abyss-space-sm)',
  },
});
