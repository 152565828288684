import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Breadcrumbs } from '@src/components/Breadcrumbs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { PageBody } from '@abyss/web/ui/PageBody';
import { Visibility } from '@src/components/Visibility';

/**
 * Main
 *
 * This is where the main content of the page will be rendered.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const Main = (props) => {
  const { children } = props;

  return (
    <ErrorHandler location="src/layouts/default/Main.jsx">
      <Visibility>
        <main id="app-main">
          <PageBody
            fullWidth
            css={{
              paddingTop: themeConfiguration?.theme?.space?.md,
              paddingBottom: themeConfiguration?.theme?.space?.md,
            }}
          >
            <section id="app-breadcrumbs">
              <Breadcrumbs />
            </section>
            {children}
          </PageBody>
        </main>
      </Visibility>
    </ErrorHandler>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};
