import React, { useState } from 'react';
import { Button } from '@src/components/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Visibility } from '@src/components/Visibility';
import PropTypes from 'prop-types';
import { WithCard } from '../WithCard';

/**
 * Widget
 *
 * The logic for the Widget component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const TheWidget = (props) => {
  const {
    button,
    children,
    collapsed,
    collapsible,
    description,
    isFullscreen,
    menu,
    setIsFullscreen,
    title,
    withCard,
  } = props;

  const [isCollapsed, setIsCollapsed] = useState(collapsed || false);

  return (
    <div className="widget">
      <WithCard withCard={withCard}>
        <Flex
          className="widget-header"
          justify="space-between"
          alignItems={isEmpty(description) ? 'center' : 'flex-start'}
        >
          <div>
            <Heading offset={5}>{title}</Heading>
            {!isEmpty(description) && <Text>{description}</Text>}
          </div>
          <Layout.Group className="widget-options">
            {!isUndefined(button) && button}
            {!isEmpty(menu) && (
              <div>
                <DropdownMenu
                  label="options"
                  hideLabel
                  after={<IconSymbol icon="more_vert" color="$gray7" />}
                  menuItems={menu || []}
                  css={{
                    '.abyss-dropdown-menu-trigger': {
                      background: 'none',
                      padding: 0,
                      margin: 0,
                    },
                  }}
                />
              </div>
            )}
            {collapsible === true && (
              <Button
                variant="ghost"
                rounded
                size="$sm"
                onClick={() => {
                  return setIsCollapsed(!isCollapsed);
                }}
                css={{
                  border: '1px solid var(--abyss-colors-interactive1) !important',
                  borderRadius: '4px !important',
                  margin: 0,
                }}
              >
                {isCollapsed ? <IconSymbol icon="expand_less" /> : <IconSymbol icon="expand_more" />}
              </Button>
            )}
            <Visibility
              accessor="FullScreenWidgets"
              enabledEnvironments={[]}
              disabledEnvironments={['Local', 'Development', 'Stage', 'Production']}
            >
              <div>
                <button type="button">
                  <IconSymbol
                    icon="fullscreen"
                    css={{
                      cursor: 'pointer',
                      background: 'none',
                      padding: 'var(--abyss-space-sm)',
                      margin: 0,
                    }}
                    onClick={() => {
                      return setIsFullscreen(!isFullscreen);
                    }}
                  />
                </button>
              </div>
            </Visibility>
          </Layout.Group>
        </Flex>
        {!isCollapsed && (
          <React.Fragment>
            <Divider className="widget-divider" />
            <div className="widget-body">{children}</div>
          </React.Fragment>
        )}
      </WithCard>
    </div>
  );
};

TheWidget.propTypes = {
  button: PropTypes.node,
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  collapsible: PropTypes.bool,
  description: PropTypes.string,
  isFullscreen: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onChange: PropTypes.func,
      radios: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      title: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  setIsFullscreen: PropTypes.func,
  title: PropTypes.string.isRequired,
  withCard: PropTypes.bool,
};

TheWidget.defaultProps = {
  button: null,
  children: null,
  collapsed: false,
  collapsible: false,
  description: '',
  isFullscreen: false,
  menu: [],
  setIsFullscreen: () => {},
  withCard: true,
};
