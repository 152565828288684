import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * NumberedPagination
 *
 * Displays a pagination control for next/previous pages along with controls to navigate to a specific page number.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const NumberedPagination = (props) => {
  const { pagination } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Footer/components/NumberedPagination/NumberedPagination.jsx">
      <Visibility>
        <Styles>
          <Pagination {...pagination} />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

NumberedPagination.propTypes = {
  pagination: Pagination.propTypes,
};

NumberedPagination.defaultProps = {
  pagination: Pagination.defaultProps,
};
