import Image from 'next/image';
import React from 'react';
import { Box } from '@abyss/web/ui/Box';
import { Card } from '@abyss/web/ui/Card';
import { config } from '@abyss/web/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * Unauthorized
 *
 * Provides a screen for the user to view when they are not authorized to view the application.
 *
 * @returns {Element}
 * @constructor
 */
export const Unauthorized = () => {
  return (
    <ErrorHandler location="src/routes/public/Unauthorized/Unauthorized.jsx">
      <Visibility>
        <Styles>
          <Flex justify="center" alignItems="center" alignContent="center" direction="column">
            <Box color="" width={500} height="auto">
              <Image
                className="logo"
                alt="UHG logo"
                src="/logo.svg"
                width="0"
                height="0"
                sizes="100vw"
                priority="true"
              />
              <Card>
                <Layout.Stack grow space={12} alignLayout="center" alignItems="center">
                  <Heading offset={3}>{config('APP_NAME')}</Heading>
                  <Divider />
                  <Heading offset={0}>UNAUTHORIZED</Heading>
                  <Text>Not allowed to view the requested resource.</Text>
                </Layout.Stack>
                <Link href="/login">
                  <IconSymbol icon="arrow_back" /> Back to Login
                </Link>
              </Card>
            </Box>
          </Flex>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};
