/* eslint-disable import/no-extraneous-dependencies  */
import Head from 'next/head';
import React from 'react';
import themeConfiguration from '@src/includes/configuration/theme.json';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { ApplicationProvider as ContextProvider } from '@src/context/Application';
import { config } from '@abyss/web/tools/config';
import { createTheme } from '@abyss/web/tools/theme';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { GlobalStyles as Styles } from '@src/styles/GlobalStyles';
import { Logger } from '@src/requests/mutations/Logger';
import { msalConfig } from '@src/includes/configuration/authentication';
import { MsalProvider } from '@azure/msal-react';
import { Router } from '@src/components/Router';
import { ToastProvider } from '@abyss/web/ui/Toast';

export const AbyssTheme = createTheme('uhg', themeConfiguration);

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();

  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  /**
   * Set active account.
   */
  msalInstance.addEventCallback((event) => {
    if (event?.eventType === EventType?.LOGIN_SUCCESS && event?.payload?.account) {
      msalInstance.setActiveAccount(event?.payload?.account);
    }
  });

  /**
   * Log authentication events.
   */
  msalInstance.addEventCallback((event) => {
    (async () => {
      if (event?.eventType === EventType?.LOGIN_START) {
        await Logger({
          type: 'info',
          message: '[Event] -> Authentication: Login started.',
        });
      }

      if (event?.eventType === EventType?.LOGIN_SUCCESS && event?.payload?.account) {
        await Logger({
          type: 'info',
          message: '[Event] -> Authentication: Login succeeded.',
          data: event?.payload?.account?.username,
        });
      }

      if (event?.eventType === EventType?.LOGIN_FAILURE && event?.payload?.account) {
        await Logger({
          type: 'info',
          message: '[Event] -> Authentication: Login failed.',
          data: event?.payload?.account?.username,
        });
      }

      if (event?.eventType === EventType?.LOGOUT_START && event?.payload?.account) {
        await Logger({
          type: 'info',
          message: '[Event] -> Authentication: Logout started.',
          data: event?.payload?.account?.username,
        });
      }

      if (event?.eventType === EventType?.LOGOUT_SUCCESS && event?.payload?.account) {
        await Logger({
          type: 'info',
          message: '[Event] -> Authentication: Logout succeeded.',
          data: event?.payload?.account?.username,
        });
      }

      if (event?.eventType === EventType?.LOGOUT_FAILURE && event?.payload?.account) {
        await Logger({
          type: 'info',
          message: '[Event] -> Authentication: Logout failed.',
          data: event?.payload?.account?.username,
        });
      }

      if (event?.eventType === EventType?.LOGOUT_END && event?.payload?.account) {
        await Logger({
          type: 'info',
          message: '[Event] -> Authentication: Logout ended.',
          data: event?.payload?.account?.username,
        });
      }
    })();
  });
});

/**
 * client
 *
 * The main file responsible to handle global application logic.
 *
 * @returns {JSX.Element}
 */
export const client = () => {
  return (
    <ErrorHandler location="src/client.jsx">
      <MsalProvider instance={msalInstance}>
        <AbyssProvider theme={AbyssTheme}>
          <Styles theme={AbyssTheme}>
            <ToastProvider />
            <ContextProvider>
              <Head>
                <title>
                  {config('APP_ENV') !== 'prod' ? `${config('APP_NAME')} | ${config('APP_ENV')}` : config('APP_NAME')}
                </title>
              </Head>
              <Router />
            </ContextProvider>
          </Styles>
        </AbyssProvider>
      </MsalProvider>
    </ErrorHandler>
  );
};
