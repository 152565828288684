import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-data-table-root': {
    '.abyss-data-table-custom-header-section': {
      margin: 0,
    },
    '.abyss-data-table-subheader-lower-container': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '.abyss-data-table-pagination-top-root': {
      marginTop: 'auto',
    },
    td: {
      overflowX: 'initial',
      wordBreak: 'break-all',
      '> div': {
        width: '100%',
      },
      '.abyss-loading-spinner-root': {
        width: '35px',
      },
      '.truncate': {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: 'inherit',
      },
    },
    '.abyss-table-row-expanded': {
      backgroundColor: 'var(--abyss-colors-white)',
      borderTop: '2px solid var(--abyss-colors-interactive1)',
      width: '100%',
      '& .abyss-table-cell-expanded': {
        display: 'table-cell',
        width: '100%',
        'table tr td': {
          '> div': {
            width: 'auto',
          },
        },
      },
    },
  },
});
