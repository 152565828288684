import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { DataTable } from '@abyss/web/ui/DataTable';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEqual, isNil, isUndefined, merge } from 'lodash';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import tableConfiguration from './includes/configuration.json';

/**
 * Table
 *
 * Reusable table component to be used/reused throughout various screens in the UI.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const {
    columns,
    configuration,
    error,
    headerLeft,
    isLoading,
    noDataMessage,
    requestArgs,
    requestFunction,
    requestKey,
    rows,
    totalPages,
    totalRecords,
  } = props;

  const [initialRequest, setInitialRequest] = useState(false);
  const [previousPayload, setPreviousPayload] = useState({});

  const [resultsPerPage, setResultsPerPage] = useState(
    configuration?.pageSizeDefault || tableConfiguration?.pageSizeDefault
  );

  const [sortBy, setSortBy] = useState(requestArgs?.sort);

  const paginationProps = usePagination({ pageSize: resultsPerPage, start: requestArgs?.page, pages: totalPages });

  const currentPage = paginationProps?.pageIndex;

  /**
   * Defines the final table configuration to pass to abyss useDataTable hook.
   */
  const theConfiguration = useMemo(() => {
    const mergedConfiguration = merge(
      {},
      merge({}, { ...tableConfiguration, ...{ renderSubComponent: null } }, configuration),
      {
        initialColumns: columns || [],
        uniqueStorageId: `table-${requestKey}`,
        errorMessage: !isNil(error) && (
          <Alert
            variant="error"
            title={`API Error - ${error?.request?.statusText}`}
            errorCode={error?.request?.status}
            css={{
              marginLeft: 'var(--abyss-space-md)',
              marginRight: 'var(--abyss-space-md)',
            }}
          />
        ),
        noDataMessage: (
          <Alert
            variant="info"
            title={noDataMessage || 'No records found.'}
            css={{
              marginLeft: 'var(--abyss-space-md)',
              marginRight: 'var(--abyss-space-md)',
            }}
          />
        ),
      }
    );

    if (!isUndefined(configuration?.pageSizeOptions)) {
      mergedConfiguration.pageSizeOptions = configuration?.pageSizeOptions;
    }

    if (!isUndefined(configuration?.pageSizeDefault)) {
      mergedConfiguration.pageSizeDefault = configuration?.pageSizeDefault;
    }

    return mergedConfiguration;
  }, [tableConfiguration, configuration, columns, error, noDataMessage, requestKey]);

  /**
   * Table configuration.
   */
  const tableState = useDataTable(theConfiguration);

  tableState.state.isLoading = !!isLoading;

  const handleRequest = async (payload) => {
    if (!isEqual(payload, previousPayload)) {
      setPreviousPayload(payload);
      await requestFunction(payload);
    }
  };

  /**
   * Triggers the initial data fetch request.
   */
  useEffect(() => {
    if (!initialRequest && !isNil(currentPage) && !isNil(resultsPerPage) && !isNil(sortBy)) {
      (async () => {
        await handleRequest({
          ...requestArgs,
          ...{
            page: currentPage,
            size: resultsPerPage,
            sort: sortBy,
          },
        });
        setInitialRequest(true);
      })();
    }
  }, [initialRequest, currentPage, resultsPerPage, sortBy]);

  /**
   * When a table column is clicked, update the state with the column name to sort by and the sorting direction.
   */
  useEffect(() => {
    if (!isNil(tableState?.state?.sortBy?.[0]?.id) && !isNil(tableState?.state?.sortBy?.[0]?.desc)) {
      setSortBy(
        `${tableState?.state?.sortBy?.[0]?.id},${tableState?.state?.sortBy?.[0]?.desc === true ? 'desc' : 'asc'}`
      );
    }
  }, [tableState?.state?.sortBy]);

  /**
   * When any of the request arguments change, fetch new data matching the requested arguments.
   */
  useEffect(() => {
    if (initialRequest && !isNil(currentPage) && !isNil(resultsPerPage) && !isNil(sortBy)) {
      (async () => {
        await handleRequest({
          ...requestArgs,
          ...{
            page: currentPage,
            size: resultsPerPage,
            sort: sortBy,
          },
        });
      })();
    }
  }, [initialRequest, currentPage, resultsPerPage, sortBy, requestArgs]);

  /**
   * Data is passed into this component via the rows prop. Update the data table with the newly fetched data.
   */
  useEffect(() => {
    if (tableState?.rows !== rows) {
      tableState?.setColumns(columns, true);
      tableState?.setData(rows, true);
    }
  }, [tableState?.rows, rows, columns]);

  return (
    <ErrorHandler location="src/components/Table-query/Table.jsx">
      <Visibility>
        <Styles>
          <Header
            {...{
              currentPage: currentPage + 1,
              pageSizeOptions: theConfiguration?.pageSizeOptions,
              pagination: paginationProps,
              resultsPerPage,
              setResultsPerPage,
              totalResults: totalRecords,
              headerLeft,
              showGoToPage: theConfiguration?.showGoToPage,
            }}
          />
          <DataTable title="" className={isLoading ? 'isLoading' : ''} hideTitleHeader tableState={tableState} />
          <Footer
            {...{
              pagination: paginationProps,
              resultsPerPage,
              totalResults: totalRecords,
            }}
          />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      Cell: PropTypes.func,
      Filter: PropTypes.func,
      sortable: PropTypes.bool,
      width: PropTypes.number,
    })
  ),
  configuration: PropTypes.shape({
    pageSizeDefault: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
    showGoToPage: PropTypes.bool,
  }),
  error: PropTypes.shape({
    request: PropTypes.shape({
      status: PropTypes.number,
      statusText: PropTypes.string,
    }),
  }),
  headerLeft: PropTypes.element,
  isLoading: PropTypes.bool,
  noDataMessage: PropTypes.string,
  requestArgs: PropTypes.shape({
    page: PropTypes.number,
    size: PropTypes.number,
    sort: PropTypes.string,
  }),
  requestFunction: PropTypes.func,
  requestKey: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  totalPages: PropTypes.number,
  totalRecords: PropTypes.number,
};

Table.defaultProps = {
  columns: [],
  configuration: {},
  error: null,
  headerLeft: null,
  isLoading: false,
  noDataMessage: null,
  requestArgs: {},
  requestFunction: null,
  requestKey: 'table',
  rows: [],
  totalPages: 0,
  totalRecords: 0,
};
