import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { NumberedPagination } from '@src/components/Table-query/components/Footer/components/NumberedPagination';
import { ResultsCount } from '@src/components/Table-query/components/ResultsCount';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * Footer
 *
 * Displays components below the table which are used to control the data within the table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Footer = (props) => {
  const { pagination, resultsPerPage, totalResults } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Footer/Footer.jsx">
      <Visibility>
        <Styles>
          <Flex
            className="row"
            justify="space-between"
            alignItems="flex-start"
            alignContent="flex-start"
            direction="row"
          >
            <ResultsCount resultsPerPage={resultsPerPage} pagination={pagination} totalResults={totalResults} />
            <NumberedPagination pagination={pagination} />
          </Flex>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Footer.propTypes = {
  pagination: NumberedPagination.propTypes.pagination,
  resultsPerPage: ResultsCount.propTypes.resultsPerPage,
  totalResults: ResultsCount.propTypes.totalResults,
};

Footer.defaultProps = {
  pagination: NumberedPagination.defaultProps.pagination,
  resultsPerPage: ResultsCount.defaultProps.resultsPerPage,
  totalResults: ResultsCount.defaultProps.totalResults,
};
