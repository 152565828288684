import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Visibility } from '@src/components/Visibility';
import { CompactPagination } from './components/CompactPagination';
import { GoToPage } from './components/GoToPage';
import { ResultsCount } from '../ResultsCount';
import { ResultsPerPage } from './components/ResultsPerPage';
import { Styles } from './includes/styles';

/**
 * Header
 *
 * Displays components above the table which are used to control the data within the table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Header = (props) => {
  const {
    currentPage,
    headerLeft,
    pageSizeOptions,
    pagination,
    resultsPerPage,
    setResultsPerPage,
    showGoToPage,
    totalResults,
  } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Header/Header.jsx">
      <Visibility>
        <Styles>
          <Grid>
            <Grid.Col
              id="headerLeft"
              span={{
                xs: '50%',
              }}
            >
              {headerLeft}
            </Grid.Col>
            <Grid.Col
              span={{
                xs: '50%',
              }}
            >
              <Flex className="topRow" justify="flex-end" alignItems="center" alignContent="flex-start" direction="row">
                <ResultsPerPage
                  pageSizeOptions={pageSizeOptions}
                  resultsPerPage={resultsPerPage}
                  setResultsPerPage={setResultsPerPage}
                />
                <CompactPagination pagination={pagination} />
                {showGoToPage === true && (
                  <GoToPage
                    navigate={pagination?.gotoPage}
                    currentPage={currentPage}
                    totalPages={pagination?.pageCount}
                  />
                )}
              </Flex>
              <Flex
                className="bottomRow"
                justify="flex-end"
                alignItems="center"
                alignContent="flex-start"
                direction="row"
              >
                <ResultsCount resultsPerPage={resultsPerPage} pagination={pagination} totalResults={totalResults} />
              </Flex>
            </Grid.Col>
          </Grid>
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

Header.propTypes = {
  currentPage: PropTypes.number,
  headerLeft: PropTypes.element,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  pagination: PropTypes.shape({
    gotoPage: PropTypes.func,
    pageCount: PropTypes.number,
  }),
  resultsPerPage: PropTypes.number,
  setResultsPerPage: PropTypes.func,
  showGoToPage: PropTypes.bool,
  totalResults: PropTypes.number,
};

Header.defaultProps = {
  currentPage: 0,
  headerLeft: null,
  pageSizeOptions: [],
  pagination: {
    gotoPage: () => {},
    pageCount: 0,
  },
  resultsPerPage: 0,
  setResultsPerPage: () => {},
  showGoToPage: false,
  totalResults: 0,
};
