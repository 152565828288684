import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty } from 'lodash';
import { TextInput } from '@abyss/web/ui/TextInput';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * GoToPage
 *
 * Allows the user to navigate directly to a specific page number of results within a data table.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const GoToPage = (props) => {
  const { currentPage, navigate, totalPages } = props;

  const [requestedPage, setRequestedPage] = useState(currentPage);

  /**
   * If the current page number is different from higher component definition, update the state.
   */
  useEffect(() => {
    if (requestedPage !== currentPage) {
      setRequestedPage(currentPage);
    }
  }, [currentPage]);

  /**
   * handleInputChange
   *
   * When the value of the input field changes, update the state.
   *
   * @param event
   */
  const handleInputChange = (event) => {
    let value = event?.target?.value;

    if (Number(value) > totalPages) {
      value = totalPages;
    }

    if (!isEmpty(value) && Number(value) <= 0) {
      value = 1;
    }

    if (value !== requestedPage) {
      setRequestedPage(value);
    }
  };

  /**
   * handlePageChange
   *
   * Uses the abyss pagination functionality to toggle the current page.
   *
   * @returns {Promise<void>}
   */

  const handlePageChange = async () => {
    if (currentPage !== requestedPage) {
      await navigate(requestedPage - 1);
    }
  };

  return (
    <ErrorHandler location="src/components/Table-query/components/Header/components/GoToPage/GoToPage.jsx">
      <Visibility>
        <Styles>
          <TextInput
            label=""
            type="number"
            width="11em"
            leftAddOn="Page"
            inputRightElement={
              <Button type="submit" variant="solid" onClick={handlePageChange}>
                Go
              </Button>
            }
            hideLabel
            value={requestedPage}
            onChange={handleInputChange}
            onKeyDown={async (event) => {
              if (event?.keyCode === 13) {
                await handlePageChange();
              }
            }}
          />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

GoToPage.propTypes = {
  currentPage: PropTypes.number,
  navigate: PropTypes.func,
  totalPages: PropTypes.number,
};

GoToPage.defaultProps = {
  currentPage: 0,
  navigate: () => {},
  totalPages: 0,
};
