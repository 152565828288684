import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * CompactPagination
 *
 * Displays a pagination control for next/previous pages along with the current page number and total page number.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const CompactPagination = (props) => {
  const { pagination } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/Header/components/CompactPagination/CompactPagination.jsx">
      <Visibility>
        <Styles>
          <Pagination {...pagination} variant="compact" />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

CompactPagination.propTypes = {
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

CompactPagination.defaultProps = {
  pagination: {
    currentPage: 0,
    totalPages: 0,
  },
};
