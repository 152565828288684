import React, { useEffect, useState } from 'react';
import { Breadcrumbs as AbyssBreadcrumbs } from '@abyss/web/ui/Breadcrumbs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isArray, isEmpty, isNull, isUndefined } from 'lodash';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { Visibility } from '@src/components/Visibility';

/**
 * Breadcrumbs
 *
 * Easily navigate between different sections within the hierarchical structure of the UI.
 *
 * @returns {Element}
 * @constructor
 */
export const Breadcrumbs = () => {
  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;

  const { currentRoutes } = useRoutesContext();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  /**
   * getTertiaryItem
   *
   * @param routes
   * @param items
   * @param primaryItem
   * @param secondaryItem
   * @returns {{}}
   */
  const getTertiaryItem = (routes = [], items = {}, primaryItem = {}, secondaryItem = {}) => {
    const theItems = { ...items };

    routes.forEach((tertiaryRoute) => {
      const tertiaryItem = {
        href: tertiaryRoute.path,
        title: tertiaryRoute.navigationLabel,
        showInBreadcrumbs: tertiaryRoute.showInBreadcrumbs,
      };

      const tertiaryMatch = router.matchPath(
        {
          path: tertiaryRoute.path,
          exact: false,
          strict: false,
        },
        path
      );

      // Include tertiary items
      if (!isNull(tertiaryMatch)) {
        if (primaryItem?.showInBreadcrumbs) {
          theItems.primary = primaryItem;
        }

        if (secondaryItem?.showInBreadcrumbs) {
          theItems.secondary = secondaryItem;
        }

        if (!isEmpty(tertiaryMatch.params)) {
          Object.keys(tertiaryMatch.params).forEach((key) => {
            theItems.primary.href = primaryItem?.href.replace(`:${key}`, tertiaryMatch.params[key]);
            theItems.secondary.href = secondaryItem?.href.replace(`:${key}`, tertiaryMatch.params[key]);
            tertiaryItem.href = tertiaryItem.href.replace(`:${key}`, tertiaryMatch.params[key]);
          });
        }

        if (tertiaryItem?.showInBreadcrumbs) {
          theItems.tertiary = tertiaryItem;
        }
      }
    });

    return theItems;
  };

  /**
   * getSecondaryItem
   *
   * @param routes
   * @param items
   * @param primaryItem
   * @returns {{}}
   */
  const getSecondaryItem = (routes = [], items = {}, primaryItem = {}) => {
    let theItems = { ...items };

    routes?.forEach((secondaryRoute) => {
      const secondaryItem = {
        href: secondaryRoute.path,
        title: secondaryRoute.navigationLabel,
        showInBreadcrumbs: secondaryRoute.showInBreadcrumbs,
      };

      const secondaryMatch = router.matchPath(
        {
          path: secondaryRoute.path,
          exact: false,
          strict: false,
        },
        path
      );

      // Include secondary items
      if (!isNull(secondaryMatch)) {
        if (primaryItem?.showInBreadcrumbs) {
          theItems.primary = primaryItem;
        }

        if (!isEmpty(secondaryMatch.params)) {
          Object.keys(secondaryMatch.params).forEach((key) => {
            theItems.primary.href = primaryItem.href.replace(`:${key}`, secondaryMatch.params[key]);
            secondaryItem.href = secondaryItem.href.replace(`:${key}`, secondaryMatch.params[key]);
          });
        }

        if (secondaryItem?.showInBreadcrumbs) {
          theItems.secondary = secondaryItem;
        }
      } else if (!isEmpty(secondaryRoute?.children) && isArray(secondaryRoute?.children)) {
        theItems = getTertiaryItem(secondaryRoute?.children, theItems, primaryItem, secondaryItem);
      }
    });

    return theItems;
  };

  /**
   * getPrimaryItem
   *
   * @param routes
   * @param items
   * @returns {{}}
   */
  const getPrimaryItem = (routes = [], items = {}) => {
    let theItems = { ...items };

    routes?.forEach((primaryRoute) => {
      const primaryItem = {
        href: primaryRoute.path,
        title: primaryRoute.navigationLabel,
        showInBreadcrumbs: primaryRoute.showInBreadcrumbs,
      };

      const primaryMatch = router.matchPath(
        {
          path: primaryRoute.path,
          exact: false,
          strict: false,
        },
        path
      );

      // Include primary items
      if (primaryRoute.path !== '/' && !isNull(primaryMatch)) {
        if (primaryItem?.showInBreadcrumbs) {
          theItems.primary = primaryItem;
        }
      } else if (!isEmpty(primaryRoute?.children) && isArray(primaryRoute?.children)) {
        const secondaryItem = getSecondaryItem(primaryRoute?.children, theItems, primaryItem);

        theItems = { ...items, ...secondaryItem };
      }
    });

    return theItems;
  };

  /**
   * getItems
   *
   * @param routes
   * @returns {{}}
   */
  const getItems = (routes = []) => {
    const items = {
      root: {},
      primary: {},
      secondary: {},
      tertiary: {},
    };

    routes.forEach((primaryRoute) => {
      // Always include the home page
      if (primaryRoute.path === '/') {
        items.root = {
          href: primaryRoute.path,
          title: primaryRoute.navigationLabel,
        };
      }
    });

    const breadcrumbItems = getPrimaryItem(routes, items);

    if (isUndefined(breadcrumbItems.primary.title)) {
      breadcrumbItems.primary = {};
    }

    if (isUndefined(breadcrumbItems.secondary.title)) {
      breadcrumbItems.secondary = {};
    }

    if (isUndefined(breadcrumbItems.tertiary.title)) {
      breadcrumbItems.tertiary = {};
    }

    return breadcrumbItems;
  };

  /**
   * Set the current breadcrumb items based on the contextual routes.
   */
  useEffect(() => {
    if (!isEmpty(currentRoutes) || isArray(currentRoutes)) {
      const breadcrumbItems = [];

      const items = getItems(currentRoutes);

      if (!isEmpty(items?.root)) {
        breadcrumbItems.push(items.root);
      }

      if (!isEmpty(items?.primary)) {
        breadcrumbItems.push(items.primary);
      }

      if (!isEmpty(items?.secondary)) {
        breadcrumbItems.push(items.secondary);
      }

      if (!isEmpty(items?.tertiary)) {
        breadcrumbItems.push(items.tertiary);
      }

      if (!isEmpty(breadcrumbItems)) {
        setBreadcrumbs(breadcrumbItems);
      }
    }
  }, [path, currentRoutes]);

  if (path === '/') {
    return <React.Fragment />;
  }

  return (
    <ErrorHandler location="src/components/Breadcrumbs/Breadcrumbs.jsx">
      <Visibility>
        <Grid>
          <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' }}>
            <AbyssBreadcrumbs divider="/" items={breadcrumbs} />
          </Grid.Col>
        </Grid>
      </Visibility>
    </ErrorHandler>
  );
};
