import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { ResultCount } from '@abyss/web/ui/Pagination';
import { Visibility } from '@src/components/Visibility';
import { Styles } from './includes/styles';

/**
 * ResultsCount
 *
 * Displays the range of results currently displayed along with the total number of results.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ResultsCount = (props) => {
  const { resultsPerPage, pagination, totalResults } = props;

  return (
    <ErrorHandler location="src/components/Table-query/components/ResultsCount/ResultsCount.jsx">
      <Visibility>
        <Styles>
          <ResultCount {...pagination} resultsTotalCount={totalResults} pageSize={resultsPerPage} />
        </Styles>
      </Visibility>
    </ErrorHandler>
  );
};

ResultsCount.propTypes = {
  resultsPerPage: PropTypes.number,
  pagination: PropTypes.shape({
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  totalResults: PropTypes.number,
};

ResultsCount.defaultProps = {
  resultsPerPage: 0,
  pagination: {
    currentPage: 0,
    totalPages: 0,
  },
  totalResults: 0,
};
