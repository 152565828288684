import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.abyss-pagination-results-root': {
    paddingTop: 'var(--abyss-space-xs)',
  },
  '.row': {
    paddingTop: 'var(--abyss-space-sm)',
    paddingBottom: 'var(--abyss-space-sm)',
  },
});
